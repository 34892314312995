<template>
  <div class="input-submit">
    <label v-if="label" class="sub-text-s2">
      {{ label }}
    </label>
    <div class="input-wrapper">
      <input
        class="sub-text-s2"
        type="text"
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @keypress.enter="$emit('submitAction')"
      />
      <button-basic
        :text="btnText"
        bg-color="#ffffff"
        padding="14px 14px"
        color="#5c5776"
        :border="true"
        text-size="s2"
        :disabled="state.disableBtn"
        @action="$emit('submitAction')"
      ></button-basic>
    </div>
  </div>
</template>

<script>
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import {computed, reactive} from "vue";

export default {
  name: "InputSubmit",
  components: { ButtonBasic },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "submitAction", ""],
  setup(props,{emit}) {
    const state = reactive({
      isRoundShape: props.shape === "round",
      disableBtn: computed(() => {
        let text = props.modelValue ;
        return !text || text.trim().length === 0;
      })

    });
    return { state};
  }
};
</script>

<style scoped>
.input-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 8px;
  width: 100%;
}

.input-wrapper input {
  border: 1px solid #e8e7ed;
  border-radius: 4px;
  padding: 12px 20px;
  width: 100%;
  height: 48px;
}

.input-wrapper button {
  height: 48px;
}

.input-submit label {
  display: inline-block;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .input-wrapper input {
    width: unset;
    flex-grow: 1;
  }

  .input-wrapper {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .input-wrapper button{
    width: fit-content;
  }
}
</style>
