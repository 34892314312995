<template>
  <div class="multipart-file-upload-container">
    <div
      class="upload-area input-multi-part-file bg-gray-01"
      :class="{ disabled: isUploading }"
      @click="triggerFileInput"
      @dragover.prevent
      @dragenter.prevent="onDragEnter"
      @dragleave="onDragLeave"
      @drop.prevent="onDrop"
    >
      <input
        ref="fileInput"
        :name="name"
        type="file"
        accept="video/mp4"
        @change="onFileChange"
      />
      <!--      accept=".mp4"-->
      <div>
        <button class="upload-btn">
          <span>파일 선택</span>
          <upload-icon fill-color="rgba(36, 110, 241, 1)"></upload-icon>
        </button>
        <p class="sub-text-s3 info">
          원하는 파일을 여기에 끌어오거나 클릭하여 업로드해 주세요.
        </p>
        <p class="sub-text-s3 text-gray-second caption">
          * 업로드 파일 형식 : mp4
        </p>
        <p v-if="settingComment" class="sub-text-s3 text-gray-second caption">
          {{ settingComment }}
        </p>
      </div>
    </div>
  </div>
  <div v-if="state.errorMessage">
    <p class="sub-text-s3 text-red-50 error-message">
      {{ state.errorMessage }}
    </p>
  </div>
</template>

<script>
import { reactive, ref, watch, onMounted } from "vue";
import UploadIcon from "@/components/console/icons/UploadIcon.vue";
import { useStore } from "vuex";

export default {
  name: "EntMultipartFileUploader",
  components: { UploadIcon },
  props: {
    fileId: {
      type: String,
      default: "",
    },
    triggerRouteLeave: {
      type: Boolean,
      default: false,
    },
    isOnlyVideoEncoding: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "multipart-file-uploader",
    },
    settingComment: {
      type: String,
      default: "",
    },
    isUploading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const fileInput = ref(null);
    const files = ref([]);
    const store = useStore();

    const state = reactive({
      isUploading: false,
      triggerDeleteUploadingMultipart: false,
      errorMessage: "",
      uploadingData: {
        model: "",
        resourceId: "",
        fileName: "",
        size: "",
        fileFormat: "",
        isOnlyVideoEncoding: true,
        payload: {
          fileName: "",
          uploadId: "",
          uploadKey: "",
        },
        numberOfFiles: 0,
        progress: 0,
        chunks: [],
        payloadParts: [],
        payloadMultipart: {
          fileName: "",
        },
        isNetworkError: false,
        isUploading: false,
        isUploadSuccess: false,
        triggerDeleteUploadingMultipart: false,
      },
      fileId: "",
    });

    onMounted(() => {
      state.uploadingData.model = "content";
      state.uploadingData.resourceId = props.fileId; // 콘텐츠 리소스 아이디
      state.uploadingData.isOnlyVideoEncoding = props.isOnlyVideoEncoding;
    });

    const triggerFileInput = () => {
      if (!props.isUploading) {
        fileInput.value.click();
      }
    };

    const onDragEnter = (event) => {
      if (!props.isUploading) {
        event.currentTarget.classList.add("dragover");
      }
    };

    const onDragLeave = (event) => {
      if (!props.isUploading) {
        event.currentTarget.classList.remove("dragover");
      }
    };

    const onDrop = (event) => {
      if (!props.isUploading) {
        event.currentTarget.classList.remove("dragover");
        handleFiles(event.dataTransfer.files);
      }
    };

    watch(
      () => props.triggerRouteLeave,
      (newVal) => {
        if (newVal && state.isUploading) {
          actions.deleteUploadingMultipart();
        }
      }
    );

    const onFileChange = (event) => {
      handleFiles(event.target.files || event.dataTransfer.files);
    };

    const handleFiles = (fileList) => {
      const files = fileList;
      let file = files[0];
      if (file.type !== "video/mp4") {
        state.errorMessage = "파일 확장자를 확인해주세요.";
        return;
      }
      uploadFile(file);
    };

    const uploadFile = async (fileData) => {
      state.errorMessage = "";
      let file = fileData;

      state.uploadingData.fileName = file.name;
      state.uploadingData.fileFormat = file.type;

      // 6MB = 1024 * 1024 * 6;
      // 10KB = 1024 * 10
      const chunkSize =
        file.size > 1024 * 1024 * 1024 ? 1024 * 1024 * 60 : 1024 * 1024 * 6;
      const chunks = [];
      let offset = 0;

      while (offset < file.size) {
        let chunk = file.slice(offset, offset + chunkSize);
        chunks.push(chunk);
        offset += chunkSize;
      }

      state.uploadingData.chunks = chunks;
      state.uploadingData.fileFormat = file.type;
      state.uploadingData.numberOfFiles = chunks.length;

      const fileName = `${
        process.env.NODE_ENV === "development" ? "dev" : "prd"
      }_content_${props.fileId}.mp4`;

      state.uploadingData.payload.fileName = fileName;

      state.uploadingData.payloadParts = [];
      fileInput.value.value = "";
      store.dispatch("contents/addMultipartFile", state.uploadingData);

      await store.dispatch(
        "contents/postMultipartFile",
        state.uploadingData.resourceId
      );
      actions.initialStateUploadingData();
    };

    const actions = {
      deleteUploadingMultipart: () => {
        state.triggerDeleteUploadingMultipart = true;
      },
      initialStateUploadingData: () => {
        state.uploadingData.fileName = "";
        state.uploadingData.fileFormat = "";
        state.uploadingData.chunks = [];
        state.uploadingData.fileFormat = "";
        // state.uploadingData.numberOfFiles = 0;
        state.uploadingData.progress = 0;
        state.uploadingData.payload.fileName = "";
        state.uploadingData.payloadParts = [];
      },
    };

    return {
      state,
      actions,
      fileInput,
      files,
      triggerFileInput,
      onDragEnter,
      onDragLeave,
      onDrop,
      onFileChange,
    };
  },
};
</script>

<style scoped>
.labs-page {
  display: flex;
  gap: 30px;
}

.upload-area {
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 24px;
  border-radius: 4px;
  border: 1px dashed #dcdcdc;
  cursor: pointer;
}

.upload-area:hover {
  border-color: #246ef1;
}

.upload-btn {
  padding: 0;
  margin-bottom: 12px;
  display: flex;
  gap: 8px;
}

.upload-btn span {
  color: #246ef1;
}

.upload-area.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.info {
  margin-bottom: 12px;
}

.caption {
  margin-bottom: 0;
}

.title {
  margin-bottom: 6px;
}

@media (min-width: 768px) {
  .upload-area {
    flex-direction: row;
    justify-content: space-between;
  }
}

.loading-spinner {
  animation: loading-spinner 1s linear infinite;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.upload-area input {
  display: none;
}

.error-message {
  margin-top: 12px;
  margin-bottom: 0;
}
</style>
