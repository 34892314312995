<template>
  <page-loading v-if="state.pageLoading"></page-loading>
  <div v-if="!state.pageLoading" class="page-container-new">
    <page-header
      title="콘텐츠 수정"
      :back-button="true"
      :is-back-btn-action="true"
      @backBtnAction="actions.goToContentShow()"
    >
      <template #headerRightSide>
        <button-basic
          text="콘텐츠 목록 보기"
          padding="8px 16px"
          @action="actions.goToContents()"
        ></button-basic>
      </template>
    </page-header>

    <ent-contents-form
      :content="state.content"
      @cancel-content="actions.goToContentShow()"
    ></ent-contents-form>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onBeforeMount, onBeforeUnmount, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import PageLoading from "../../../components/console/loadings/PageLoading";
import PageHeader from "../../../components/console/headers/PageHeader";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import EntContentsForm from "@/components/console/forms/EntContentsForm.vue";

export default {
  name: "EntContentsEdit",
  components: {
    ButtonBasic,
    PageHeader,
    EntContentsForm,
    PageLoading,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const contentResourceId =
      route.path.split("/")[route.path.split("/").length - 2];

    const state = reactive({
      content: computed(() => {
        return store.getters["contents/content"];
      }),
      pageLoading: false,
      prevFeaturedImage: "",
      featuredImageFile: null,
    });

    onBeforeMount(() => {
      resetContent();
      setContent();
    });

    const isEntPagePath = () => {
      const currentRouter = router.options.history.state.current;
      return currentRouter.includes("/console/ent/contents");
    };

    const setContent = () => {
      state.pageLoading = true;
      store.dispatch("contents/getContent", { contentResourceId }).then(() => {
        state.pageLoading = false;
      });
    };
    const resetContent = () => {
      store.commit("contents/CONTENT_RESET");
    };

    onBeforeUnmount(() => {
      resetContent();
    });

    const actions = {
      goToContents: () => {
        const isEntPage = isEntPagePath();
        if (isEntPage) {
          router.push({ name: "console.ent.contents" });
        } else {
          router.push({ name: "console.contents" });
        }
      },
      goToContentShow: () => {
        const isEntPage = isEntPagePath();
        if (isEntPage) {
          router.push({ name: "console.ent.contents" });
        } else {
          router.push({
            name: "console.contents.show",
            params: { contentResourceId: state.content.resourceId },
          });
        }
      },
    };

    return {
      state,
      actions,
    };
  },
};
</script>
