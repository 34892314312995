<template>
  <div class="attachment-file__link">
    <div class="attachment-file__info">
      <template v-if="isImage(attachment.extension)">
        <avatar :avatar-img="attachment.url" shape="rounded"></avatar>
      </template>
      <template v-if="!isImage(attachment.extension)">
        <file-icon></file-icon>
      </template>

      <a
        class="name sub-text-s2"
        :href="attachment.url"
        :download="attachment.name"
        >{{ attachment.name }}</a
      >
    </div>

    <button-basic
      v-if="showDeleteBtn"
      class="close-btn"
      bg-color="transparent"
      @action="actions.deleteAttachment()"
    >
      <template #icon>
        <close-icon></close-icon>
      </template>
    </button-basic>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Avatar from "../avatars/Avatar";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";
import FileIcon from "@/components/console/icons/FileIcon.vue";

export default {
  name: "EntAttachmentFileLink",
  components: { FileIcon, CloseIcon, ButtonBasic, Avatar },
  props: {
    showDeleteBtn: {
      type: Boolean,
      default: true,
    },
    attachment: {
      type: Object,
      required: true,
    },
    directDelete: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["popAttachment"],
  setup(props, { emit }) {
    const store = useStore();

    const isImage = (extension) => {
      return (
        extension === "webp" ||
        extension === "png" ||
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "gif"
      );
    };
    const actions = {
      deleteAttachment: () => {
        if (props.directDelete) {
          store
            .dispatch("articles/deleteArticleAttachment", {
              attachmentResourceId: props.attachment.resourceId,
            })
            .then(() => {
              emit("popAttachment", props.attachment.resourceId);
            });
        } else {
          emit("popAttachment", props.attachment.resourceId);
        }
      },
    };

    return { actions, isImage };
  },
};
</script>

<style scoped>
.attachment-file__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.attachment-file__info {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.attachment-file__info .name {
  flex: 1;
  text-align: left;
  padding-left: 8px;
  text-decoration: none;
}

.close-btn {
  width: 10%;
  max-width: 48px;
  margin-left: auto;
}
</style>
